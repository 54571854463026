#main-about {
    align-items: center;
    display: flex;
    color: white;
    height: auto;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
}

#main-about img {
    max-width: 40vw;
    width: max-content;
}

.elements-top {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.elements-top p {
    position: relative;
    font-size: 30px;
    color: #bf6c06;
    font-weight: 900;
    top: -90px;
}

.row {
    align-items: center !important;
    background-color: #041c2a;
    display: flex !important;
    justify-content: center !important;
}

.elmn span {
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 800;
}

.elmn span h1 {
    font-family: 'Geometria';
    color: #bf6c06;
    font-size: 60px;
}

.elmn span p {
    color: #ffffff;
    border-bottom: 2px solid #424242;
    font-size: 30px;
    font-family: 'Poppins';
}

.elmn p {
    color: white;
    user-select: text;
}

.elmn {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.row2 {
    justify-content: flex-start !important;
    padding-right: 25rem;
}

main.row3 .elmn {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row3 .elmn img {
    min-width: 20rem;
}

.row3 .elmn #elm>span {
    margin-top: -7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-block: 2rem;
}

.row3 .elmn #elm>span h2 {
    font-size: 1.8rem;
    line-height: 150%;
    font-family: 'Poppins';
}

.row3 .elmn #elm>span button {
    width: 14rem;
    height: 3.6rem;
    font-size: 1.4rem;
    border-radius: 14rem;
    margin-block: 1rem;
}

@media (max-width: 1400px) {
    .rowInitial {
        display: none !important;
        visibility: hidden;
    }

    #main-about-elementos,
    .row,
    .row2 {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        padding-right: 0vh;
        flex-flow: column;
    }

    .row img {
        display: none;
    }

    .row2,
    .row3 img {
        display: block;
    }

    .elmn,
    #elm {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        line-height: 250%;
        text-align: center;
        padding: 2rem;
        width: 100%;
        word-break: keep-all;
    }

    .elmn span p {
        border: none;
    }

    .elmn span h1 {
        font-size: 2.7vh;
        line-height: 120%;
    }

    .elmn p {
        font-size: 1.2rem;
    }

    .row .elmn #elm>span h1 {
        padding-block: 1rem;
    }

    .Regst {
        flex-direction: column;
    }

    .infoRight p {
        font-size: 0.8rem !important;
        text-align: center !important;
    }

    .Regst img {
        display: none;
    }

    .Regst .left {
        height: 10%;
        background-color: transparent;
        position: absolute;
        top: 7rem;
        overflow: visible;
    }

    .Regst .right {
        width: 80%;
        border-radius: 4px;
        height: 62%;
    }

    .mainRight {
        width: 70% !important;
    }

}


body.register {
    overflow: hidden;
}

body.register .row {
    display: none !important;
}

body main:nth-child(1) {
    display: flex !important;
    visibility: visible !important;
}

#main-register {
    align-items: center;
    display: none;
    color: white;
    height: calc(100% - 0.6vw) !important;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.Regst {
    min-width: 72%;
    width: 72%;
    height: 80%;

    display: flex;
    align-items: center;
    justify-content: center;

}

.left {
    width: var(--wdtLeft);
    background-color: #042031;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.row5 #elm {
    background-color: rgba(19, 19, 19, 0.116);
    border-radius: 3px;
    height: 70%;
    width: 30%;

    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.row5 img {
    max-width: 15rem;
}

.right {
    width: calc(100% - var(--wdtLeft));
    height: 100%;
    background-color: #dfe1f8;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: var(--PrimaryColor);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.infoRight {
    width: 100%;

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    line-height: 110%;
    padding-block: .5vw;
    font-size: 1.8rem;

    word-break:keep-all;
}

.infoRight label{
    font-weight: 900;
    font-size: 1.4rem;
    white-space: nowrap;
}

.infoRight p {
    font-family: 'Mont';
    font-size: 0.92rem;
    word-break: normal;
}

.mainRight {
    width: 47%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.row5 .titleRegister p {
    font-size: 2rem;
    font-weight: 900;
}


.right input {
    width: 100%;
    padding: 0.5rem;

    border-radius: 2px;

    font-family: 'Montserrat';
    opacity: .5;

    color: black;
    margin-block: .4rem;
    background-color: #f2f2f9d9;
}

.right input:focus {
    opacity: 1;
}

.right button {
    margin-block-start: .2vw;

    width: 100%;
    height: 2.2rem;

    background-color: var(--PrimaryColor);
    border-radius: .3rem;
    color: white;
    font-family: 'Montserrat';
}
