@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

@font-face {
    font-family: 'Geometria';
    src: url(https://fontsfree.net//wp-content/fonts/basic/sans-serif//FontsFree-Net-Geometria-ExtraBold.ttf);
}

:root {
    --PrimaryColor: #04273d;
    --wdtLeft: 60%
}

* {
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    font-style: normal;
    margin: 0;
    text-decoration: none;
    list-style: none;
    transition: all 1s ease;
    letter-spacing: 0.7px;
    outline: none;
    border: 0;
    font-weight: 900;
}

body {
    background-color: var(--PrimaryColor);
    font-family: 'Montserrat';
    user-select: none;
}

::-webkit-scrollbar {
    background-color: #13131368;
    width: 0.4vw;
}

::-webkit-scrollbar-track {
    border-radius: 0;
}

::-webkit-scrollbar-corner {
    background: 0 0;
}

::-webkit-scrollbar-thumb {
    border-radius: 4vh;
    position: absolute;
    width: 4vw;
    height: 4vw;
    background-color: #154969;
}


