.menuOpen {
    color: white;
    font-size: 2rem;

    cursor: pointer;
}

@media (max-width: 999px) {
    #header {
        display: none;
    }

    .menuOpen {
        display: block !important;

        position: fixed;
        z-index: 999999999999999;
        align-items: center;
        justify-content: flex-end;
        font-size: 2rem;
        margin: 1rem;

    }

    .navList a {
        color: white;
    }

    .navList a:not(.button):hover {
        color: white !important;
    }

    .navList li {
        font-size: 1rem;
    }

    .navList {
        position: absolute;
        width: 100%;
        height: 100vh;
        background-color: var(--PrimaryColor);
        position: fixed;
        z-index: 99999;

        top: 0;
        left: 0;
        margin: 0;

        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        gap: 20px;

        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.1rem;

        display: none;
    }

    .navList.active {
        display: block;
    }

    .navList li {
        margin: 0;
    }

    .navList .button {
        background-color: white;
        border-radius: 4rem;
        font-weight: 700;
        font-size: 0.7rem;
        line-height: 2.3rem;
        border: none;
        text-transform: uppercase;
        text-decoration: none;

        display: inline-block;
        padding: 1vh 2.6vh;
        margin: 1vh;

        white-space: nowrap;
        color: var(--PrimaryColor);
    }

    ul,
    li,
    a {
        margin: 0;
        padding: 0;
    }

    .social-links {
        display: flex;
        align-items: center;
        gap: 3.2vh;
        justify-content: center;
    }

    body.menuExpanded {
        overflow: hidden;
    }

    body.menuExpanded #header {
        display: flex;
    }

    body.menuExpanded .social-links,
    body.menuExpanded .headerMenu,
    body.menuExpanded .button {
        display: flex !important;
    }

    body.menuExpanded main,
    body.menuExpanded .menuOpen {
        display: none !important;
    }
}


div.headerMenu {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 4vh;

    position: relative;
    left: 0;
    top: -2rem !important;
}

.menuClose {
    color: white;
    font-size: 2rem;
}
