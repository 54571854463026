@import "./css/global/font.css";
@import "./css/global/global.css";
@import "./css/global/header.css";
@import "./css/home/main-about.css";
@import "./css/home/mobileMenu.css";

.remove {
    opacity: 0;
    display: none !important;
    visibility: hidden;
}