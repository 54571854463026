#header {
    align-items: center;
    display: flex;
    height: 7vh;
    background-color: #042234;
    justify-content: space-between;
    position: fixed;
    max-width: 100%;
    margin: 0 auto;
    padding: 5px;
    top: 0;
    transition: all 1s cubic-bezier(0, 0.42, 0.74, 0.4);
    width: 100%;
    z-index: 1;
}

#header img {
    max-width: 8vh;
}

nav,
ul {
    display: flex;
}

#header>nav ul {
    display: flex;
    font-family: 'Mont';
    font-weight: 800;
    margin-right: 16px;
    gap: 4vh;
}

.navList {
    gap: 4vh;
}

.header-elements {
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 4vh;
}

#header>.header-elements li {
    cursor: pointer;
}

#header>.header-elements li>a {
    color: white;
    padding-bottom: 1px;
}

#header>nav>ul>li>a>ion-icon {
    color: white;
    font-size: 30px;
}


#header>.header-elements li>a:hover {
    color: #f2a20c;
}